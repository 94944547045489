import React from "react"
import { Link } from "gatsby"
import { headerTabs } from '../staticdata/tabsData'
import { Navbar, Nav, Container, NavDropdown, Col, Row } from "react-bootstrap"
import logo from '../images/slicing/home/logo.png'
const Header = ({ pageInfo, location, handleOpen, handleOpenStorytab, handleClose, handleOpenStory,openStory,handleOpenService,openServices }) => {
  return (
    <Navbar variant="dark" expand="lg" id="site-header">
      <Container fluid>
        <Container fluid>
          <Link to="/" className="link-no-style">
            <Navbar.Brand as="span">
              <img
                src={logo}
                className="d-inline-block align-top logo"
                alt="Gten Technology"
              /></Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              {
                headerTabs.map(tab => {
                  if (tab.key === 'services') {
                    return (
                      <NavDropdown
                        onClick={() => handleOpenService('services',openServices)}
                        onMouseEnter={()=>handleOpen('services')}
                        onMouseLeave={()=>handleClose()}
                        show={openServices}
                        className={(`/${tab.key}/` === location ? 'nav-link-parent full-width active' : 'nav-link-parent full-width')}
                        key={tab.key + 'maintab'}
                        title={tab.label}
                        id={tab.key}>
                        <Container>
                          <Row className="w-100 py-3">
                            <Col>
                              <h3 style={{ color: 'black' }}>{tab.label}</h3>
                            </Col>
                          </Row>

                        </Container>
                        <Container>
                          <Row className="w-100">
                            {
                              tab.submenus && tab.submenus.map(submenu => (
                                <Col className="border-top" key={submenu.key}>
                                  <ol style={{ listStyleType: 'none' }} className="pt-3">
                                    <li className="list-style-none inner-submenu"><h6>
                                      {submenu.clickable ? <a href={`/${submenu.key}`}>{submenu.label}</a> : <span>{submenu.label}</span>}
                                    </h6></li>
                                    {
                                      submenu.submenu && submenu.submenu.map(lowerMenu => (
                                        <li className="list-style-none inner-submenu pt-2" key={lowerMenu.key}>
                                          {lowerMenu.clickable ? <a href={`/${lowerMenu.key}`}>{lowerMenu.label}</a> : <span>{lowerMenu.label}</span>}</li>
                                      ))
                                    }
                                  </ol>
                                </Col>
                              ))
                            }
                          </Row>
                        </Container>

                      </NavDropdown>
                    )
                  } else if(tab.key === 'ourstory') {
                    return (
                      <NavDropdown
                        onClick={() => handleOpenStory('ourstory',openStory)}
                        onMouseEnter={()=>handleOpenStorytab('ourstory')}
                        onMouseLeave={()=>handleClose()}
                        show={openStory}
                        className={(`/${tab.key}/` === location ? 'nav-link-parent full-width active' : 'nav-link-parent full-width')}
                        key={tab.key + 'maintab'}
                        title={tab.label}
                        id={tab.key}>
                        <Container>
                          <Row className="w-100 py-3">
                            <Col>
                              <h3 style={{ color: 'black' }}>{tab.label}</h3>
                            </Col>
                          </Row>

                        </Container>
                        <Container>
                          <Row className="w-100">
                            {
                              tab.submenus && tab.submenus.map(submenu => (
                                <Col className="border-top" key={submenu.key}>
                                  <ol style={{ listStyleType: 'none' }} className="pt-3">
                                    <li className="list-style-none inner-submenu"><h6>
                                      {submenu.clickable ? <a href={`/${submenu.key}`}>{submenu.label}</a> : <span>{submenu.label}</span>}
                                    </h6></li>
                                    {
                                      submenu.submenu && submenu.submenu.map(lowerMenu => (
                                        <li className="list-style-none inner-submenu pt-2" key={lowerMenu.key}>
                                          {lowerMenu.clickable ? <a href={`/${lowerMenu.key}`}>{lowerMenu.label}</a> : <span>{lowerMenu.label}</span>}</li>
                                      ))
                                    }
                                  </ol>
                                </Col>
                              ))
                            }
                          </Row>
                        </Container>

                      </NavDropdown>
                    )
                  } else {
                    return (
                      <Link key={tab.key} to={tab.key === 'home' ? '/' : `/${tab.key}/`} className="link-no-style nav-link-parent">
                        <Nav.Link
                          as="span"
                          eventKey="home"
                          className={(`/${tab.key}/` === location && 'active') || (location === `/` && tab.key === 'home' && 'active')}>
                          {tab.label}
                        </Nav.Link>
                      </Link>
                    )
                  }
                })
              }
            </Nav>
          </Navbar.Collapse>
        </Container>

      </Container>

    </Navbar>
  )
}

export default Header
