import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
const PrimaryFooter = () => {
    return (
        <footer>
            <Container fluid className="px-0">
                <Row noGutters>
                    <Col className="footer-col">
                        <div className="footer footer-left">
                            <span className="footer-text">
                                © {new Date().getFullYear()}
                                {` `}
                                <span >GTEN Technologies. All rights reserved</span>
                            </span>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </footer>
    )
}

export default PrimaryFooter