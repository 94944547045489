export const headerTabs = [
    { key: 'home', label: 'Home', dropdown: false },
    {
        key: 'services', label: 'Services', dropdown: true, submenus: [
            {
                activeKey: 'productengineering', label: 'Digital Product Engineering', clickable: true, key: 'productengineering', submenu: [
                    { activeKey: 'productdevelopment', clickable: true, label: 'Product Development ', key: 'productdevelopment' },
                    { activeKey: 'lowcodeintelligent', clickable: false, label: 'Low Code Intelligent Automation', key: 'lowcodeintelligent' },
                    { activeKey: 'sustenance', clickable: false, label: 'Sustenance & Support', key: 'sustenance' },
                    { activeKey: 'innovationacceleration', clickable: false, label: 'Innovation Acceleration', key: 'innovationacceleration' }]
            },

            {
                activeKey: 'legacymodernization', label: 'Legacy Modernization', clickable: true, key: 'legacymodernization', submenu: [

                    { activeKey: 'cloudnative', clickable: true, label: 'Cloud Native', key: 'cloudnative' },
                    { activeKey: 'managedservices', clickable: false, label: 'Managed Services', key: 'managedservices' },
                    { activeKey: 'applicationarchitecture', clickable: false, label: 'Application Architecture Services', key: 'applicationarchitecture' }]
            },

            {
                activeKey: 'testingservice', clickable: true, label: 'Test Engineering', key: 'testingservice', submenu: [

                    { activeKey: 'testautomation', clickable: true, label: 'Test Automation', key: 'testautomation' },
                    { activeKey: 'performancetestengineering', clickable: true, label: 'Performance Test Engineering', key: 'performancetestengineering' },
                    { activeKey: 'mobiletesting', clickable: true, label: 'Mobile Application Testing', key: 'mobiletesting' },
                    { activeKey: 'salesforcetesting', clickable: true, label: 'Salesforce Testing', key: 'salesforcetesting' },
                    { activeKey: 'cloudtesting', clickable: false, label: 'Cloud Infrastructure Testing', key: 'cloudtesting' },
                    { activeKey: 'automationconsulting', clickable: false, label: 'Automation Framework Consulting', key: 'automationconsulting' },
                    { activeKey: 'testdatamanagement', clickable: false, label: 'Test Data Management', key: 'testdatamanagement' }]
            }
        ]
    },
    { key: 'industries', label: 'Industries', dropdown: false },
    { key: 'clientsuccess', label: 'Client Success', dropdown: false },
    { key: 'startupstudio', label: 'Startup Studio', dropdown: false },
    { key: 'knowledgeshare', label: 'Knowledge Share', dropdown: false },
    {
        key: 'ourstory', label: 'Our Story', dropdown: true, submenus: [
            {
                activeKey: 'ourstories', clickable: true, label: 'About Us', key: 'aboutus',
                submenu: [
                    { activeKey: 'leadershipteam', clickable: true, label: 'Leadership Team', key: 'leadershipteam' }]
            }]
    },
    // { key: 'aboutus', label: 'Our Story', dropdown: false },
    { key: 'contactus', label: 'Contact Us', dropdown: false }
]


export const ourServicesTabs = [
    { key: '/productengineering/', label: 'Digital Product Engineering' },
    { key: '/productdevelopment/', label: 'Product Development' },
    { key: '/legacymodernization/', label: 'Legacy Modernization' },
    { key: '/cloudnative/', label: 'Cloud Native' },
    { key: '/testingservice/', label: 'Test Engineering' }
]

export const usefulLinksTabs = [
    { key: 'about', label: 'About' },
    { key: 'leadershipTeam', label: 'Leadership Team' },
    { key: 'ITBlog', label: 'IT Blog' },
    { key: 'Sitemap', label: 'Sitemap' },
    { key: 'caseStudies', label: 'Case Studies' },
    { key: 'locations', label: 'Locations' },
    { key: 'careers', label: 'Careers' }
]
export const contactUsTabs = [
    { key: 'forumSupport', label: 'Forum Support' },
    { key: 'helpFAQs', label: 'Help & FAQs' },
    { key: 'contactUs', label: 'Contact Us' },
    { key: 'pricingAndPlans', label: 'Pricing And Plans' },
    { key: 'cookiesPolicy', label: 'Cookies Policy' },
    { key: 'privacyPolicy', label: 'Privacy Policy' }
]

export const aboutUsSection = {
    heading: 'About Us',
    description: `GTEN Technologies is a digital transformation consulting,  technology services & solutions company. We help our clients achieve competitive advantage through End-to-End Digital Transformation and work across Financial Services, Digital Commerce, Healthcare and Product Engineering sectors. Our team brings together strong engineering and design capabilities with deep commitment to quality in building products to maximize business impact from emerging technologies.`
}