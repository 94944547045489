import React, { Component } from 'react';
import { StaticQuery, graphql, navigate } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./Header"
import PrimaryFooter from "./PrimaryFooter"
import SecondaryFooter from "./SecondaryFooter"
import ContactUsSection from './homepage/ContactUsSection'
import { isMobile } from "react-device-detect";

class Layout extends Component {

  state = {
    isOpen: false,
    openServices: false,
    openStory: false
  }

  handleOpen = (menuItem) => {
    if (isMobile) {
      return false
    } else {
      if (menuItem === 'services')
        this.setState({ openServices: true })
      else
        this.setState({ isOpen: menuItem })
    }
  }

  handleOpenStorytab = (menuItem) => {
    console.log(menuItem, "hi")
    if (isMobile) {
      return false
    } else {
      if (menuItem === 'ourstory')
        this.setState({ openStory: true })
      else
        this.setState({ isOpen: menuItem })
    }
  }

  handleClose = () => {
    this.setState({ openServices: false, openStory: false })
  }

  handleOpenServices = (tab) => {
    if (tab === 'services') {
      this.setState((prev) => ({
        openServices: !prev.openServices
      }))
    }
    else
      this.setState({ openServices: false })
  }

  handleOpenStory = (tab) => {
    if (tab === 'ourstory') {
      this.setState((prev) => ({
        openStory: !prev.openStory
      }))
    }
    else
      this.setState({ openStory: false })
  }

  handleToggleNavbar = () => {
    this.setState((prev) => ({
      isOpen: !prev.isOpen
    }))
  }
  onClickDropdown = (tab) => {
    navigate(tab)
  }
  render() {
    const { children, pageInfo, styleClass, location, mainClass } = this.props
    const { isOpen, openServices, openStory } = this.state
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
          <>
            <Container fluid className={`px-0 main ${mainClass}`}>
              <Header
                handleOpen={isMobile ? () => false : this.handleOpen}
                handleClose={this.handleClose}
                handleToggleNavbar={this.handleToggleNavbar}
                onClickDropdown={this.onClickDropdown}
                isOpen={isOpen}
                openServices={openServices}
                pageInfo={pageInfo}
                location={location}
                openStory={openStory}
                handleOpenService={this.handleOpenServices}
                handleOpenStory={this.handleOpenStory}
                handleOpenStorytab={this.handleOpenStorytab} />
              <Row noGutters className={styleClass}>
                <Col>
                  <main>{children}</main>
                </Col>
              </Row>
            </Container>
            {pageInfo && pageInfo.pageName !== 'Contact Us' && <ContactUsSection />}
            <SecondaryFooter />
            <PrimaryFooter />
          </>
        )}
      />
    )
  }
}

export default Layout;