import React from 'react'
import { Col } from 'react-bootstrap'

const FooterSectionComponent = ({ tabs, sectionHeading }) => {
    return (
        <Col>
            <h3 style={sectionHeading === "" ? { visibility: 'hidden' } : { visibility: 'visible' }}>{sectionHeading || "Adesh Kumar"}</h3>
            <hr />
            <ol>
                <li key={tabs.key}>{tabs.clickable ? <a className="nav-link p-0 text-light" href={`/${tabs.key}/`}>{tabs.label}</a> : <span>{tabs.label}</span>}</li>
                {
                    tabs && tabs.submenu && tabs.submenu.map(tab => (
                        <li key={tab.key}>{tab.clickable ? <a className="nav-link p-0 text-light" href={`/${tab.key}/`}>{tab.label}</a> : <span>{tab.label}</span>}</li>
                    ))
                }
            </ol>
        </Col>
    )
}

export default FooterSectionComponent