import { API_CALL } from '../../config'
const apiRoot = `${API_CALL}/api/v1`

export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
}

export const addSubscriberMail = (data) => {
    const url = `${apiRoot}/subscriber`
    let method = HTTP_METHODS.POST
    let body = data
    return callApi(url, method, body)
}

export const sendContactUsMail = (data) => {
    const url = `${apiRoot}/mail/contactus`
    let method = HTTP_METHODS.POST
    let body = data
    return callApi(url, method, body)
}

const callApi = (url, method, body) => {
    var requestOptions = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }
    if (body !== null) {
        requestOptions.body = JSON.stringify(body)
    }

    return fetch(url, requestOptions)
}
